import { registrationActions, RegistrationActionsTypes } from "./actions";
import {
  checkFieldValueForError,
  redirectOnSuccessfulLoginRegister,
} from "../shared/utils";
import { getOppositeId, setErrorsFromRegisterResponse } from "./utils";

export enum RegistrationFields {
  Email = "e_mail",
  RepeatEmail = "repeat_email",
  Password = "password",
  RepeatPassword = "repeat_password",
  Mobile = "tel_num",
  PersonalId = "egn",
  Name = "first_name",
  Surname = "family",
  MiddleName = "sec_name",
}

interface FieldState {
  value: string;
  error: boolean;
  touched: boolean;
}

export type RegistrationFormFields = Record<RegistrationFields, FieldState>;

export enum Checkbox {
  Male = "male",
  Female = "female",
}

interface CheckboxesValue {
  [Checkbox.Male]: boolean;
  [Checkbox.Female]: boolean;
}

export interface RegistrationState {
  checkboxes: CheckboxesValue;
  isRegistrationModalOpen: boolean;
  genderError: boolean;
  [RegistrationFields.Email]: FieldState;
  [RegistrationFields.RepeatEmail]: FieldState;
  [RegistrationFields.Password]: FieldState;
  [RegistrationFields.RepeatPassword]: FieldState;
  [RegistrationFields.Mobile]: FieldState;
  [RegistrationFields.PersonalId]: FieldState;
  [RegistrationFields.Name]: FieldState;
  [RegistrationFields.Surname]: FieldState;
  [RegistrationFields.MiddleName]: FieldState;
}

export const initialState: RegistrationState = {
  [RegistrationFields.Email]: {
    value: "",
    error: false,
    touched: false,
  },
  [RegistrationFields.RepeatEmail]: {
    value: "",
    error: false,
    touched: false,
  },
  [RegistrationFields.Password]: {
    value: "",
    error: false,
    touched: false,
  },
  [RegistrationFields.RepeatPassword]: {
    value: "",
    error: false,
    touched: false,
  },
  [RegistrationFields.Mobile]: {
    value: "+359",
    error: false,
    touched: false,
  },
  [RegistrationFields.PersonalId]: {
    value: "",
    error: false,
    touched: false,
  },
  [RegistrationFields.Name]: {
    value: "",
    error: false,
    touched: false,
  },
  [RegistrationFields.Surname]: {
    value: "",
    error: false,
    touched: false,
  },
  [RegistrationFields.MiddleName]: {
    value: "",
    error: false,
    touched: false,
  },
  checkboxes: {
    [Checkbox.Male]: false,
    [Checkbox.Female]: false,
  },
  isRegistrationModalOpen: false,
  genderError: false,
};

export function registrationReducer(
  state = initialState,
  action: RegistrationActionsTypes
): RegistrationState {
  switch (action.type) {
    case registrationActions.SetInput: {
      const { value, field } = action.payload;
      const trimmedValue = value.trim();

      return {
        ...state,
        [field]: {
          ...state[field],
          value: trimmedValue,
          error: checkFieldValueForError(field, trimmedValue),
        },
      };
    }

    case registrationActions.SetFieldTouched: {
      const { field } = action.payload;

      return {
        ...state,
        [field]: {
          ...state[field],
          touched: true,
        },
      };
    }

    case registrationActions.SetCheckboxes: {
      const { id, value } = action.payload;
      const oppositeId = getOppositeId(id);

      return {
        ...state,
        checkboxes: {
          ...state.checkboxes,
          [id]: value,
          [oppositeId]: !value,
        },
      };
    }

    case registrationActions.PasswordsDoNotMatch: {
      const passField = RegistrationFields.Password;
      const repPassField = RegistrationFields.RepeatPassword;

      return {
        ...state,
        [passField]: {
          ...state[passField],
          error: true,
          touched: true,
        },
        [repPassField]: {
          ...state[repPassField],
          error: true,
          touched: true,
        },
      };
    }

    case registrationActions.EmailsDoNotMatch: {
      const emailField = RegistrationFields.Email;
      const repEmailField = RegistrationFields.RepeatEmail;

      return {
        ...state,
        [emailField]: {
          ...state[emailField],
          error: true,
          touched: true,
        },
        [repEmailField]: {
          ...state[repEmailField],
          error: true,
          touched: true,
        },
      };
    }

    case registrationActions.TryToRegisterSuccess: {
      const { res } = action.payload;

      const error = res?.data?.errors ?? "";
      const success = res?.data?.success ?? "";

      if (error) {
        return setErrorsFromRegisterResponse(state, error);
      }

      redirectOnSuccessfulLoginRegister(success);

      return state;
    }

    case registrationActions.TryToRegisterFailed: {
      return state;
    }

    case registrationActions.ToggleRegistrationModal: {
      return {
        ...state,
        isRegistrationModalOpen: !state.isRegistrationModalOpen,
      };
    }

    case registrationActions.NoGenderSelected: {
      return {
        ...state,
        genderError: true,
      };
    }

    case registrationActions.RemoveGenderError: {
      return {
        ...state,
        genderError: false,
      };
    }

    default:
      return state;
  }
}
