import * as React from "react";
import { colors, Form, InputWithLabel, Title } from "mu-styles/dist";
import { ForgottenPasswordFields } from "./forgottenPasswordReducer";
import { StyledButton } from "./styles";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setInputField, setInputTouched } from "./actions";
import { requestReactivationCode } from "../api/apiCalls";
import {
  getForgottenPasswordEmailInput,
  getForgottenPasswordPNInput,
} from "./forgottenPasswordSelectors";

export const RequestCodeForm: React.FC = () => {
  const dispatch = useDispatch();
  const setInput = (e: React.FormEvent<HTMLInputElement>) =>
    dispatch(
      setInputField({
        field: e.currentTarget.id as ForgottenPasswordFields,
        value: e.currentTarget.value,
      })
    );
  const setTouched = (id: string) =>
    dispatch(setInputTouched(id as ForgottenPasswordFields));
  const callForgottenPassApi = () => dispatch(requestReactivationCode());

  const {
    value: emailValue,
    error: emailError,
    touched: emailTouched,
  } = useSelector(getForgottenPasswordEmailInput, shallowEqual);
  const { value, error, touched } = useSelector(
    getForgottenPasswordPNInput,
    shallowEqual
  );

  return (
    <Form>
      <Title type={"h5"}>
        Моля въвдете вашата електронна поща и персонален номер и ние ще ви
        изпратим код за реактивиране на вашата парола
      </Title>
      <InputWithLabel
        id={ForgottenPasswordFields.Email}
        color={colors.mu_grayish}
        labelText={"Електорнна поща"}
        inputPlaceholder={"Електорнна поща"}
        onChange={setInput}
        value={emailValue}
        error={emailError}
        touched={emailTouched}
        setTouched={setTouched}
        errorText={"Невалиден формат на електронна поща"}
      />
      <InputWithLabel
        id={ForgottenPasswordFields.LastFourDigits}
        color={colors.mu_grayish}
        labelText={"Последни четири цифри от ЕГН"}
        inputPlaceholder={"Последни четири цифри от ЕГН"}
        onChange={setInput}
        value={value}
        error={error}
        touched={touched}
        setTouched={setTouched}
        errorText={"Полето трябва да съдържа точно 4 цифри"}
      />
      <StyledButton onClick={callForgottenPassApi}>Изпрати имейл</StyledButton>
    </Form>
  );
};
