import axios, { AxiosError, AxiosResponse } from "axios";

// ACTIONS
import {
  registrationSuccessful,
  registrationFailed,
  tryToRegister,
  emailsDoNotMatch,
  passwordsDoNotMatch,
  noGenderSelected,
} from "../registration/actions";
import { tryToLogin, tryToLoginSuccess } from "../login/actions";
import {
  checkRecoveryResponse,
  recoveryPasswordsDoNotMatch,
  setRecoveryCodeAsMandatory,
  togglePopup,
} from "../password-reset/actions";

// TYPES
import { ThunkResult } from "../shared/types";
import { LoginErrorResponse, LoginSuccessResponse } from "../login/types";
import { RegistrationResponse } from "../registration/types";
import { RecoveryPasswordResponse } from "../password-reset/types";

// UTILS
import { delayExecution, validateEmailAndPassword } from "../shared/utils";
import { transformRegStateToExpectedRequestPayload } from "../registration/utils";
import { transformLoginStateToExpectedRequestPayload } from "../login/utils";

// CONSTANTS
import { apiUrls } from "../shared/constants";

// SELECTORS
import {
  getForgottenPasswordState,
  getPreliminaryNumber,
  getReactivationCodePasswordInput,
  getRecoveryPasswordInput,
  getRecoveryRepeatPasswordInput,
} from "../password-reset/forgottenPasswordSelectors";

const registerApi = (): ThunkResult<void> => (dispatch, state) => {
  dispatch(tryToRegister());
  const regState = state().registration;
  const { emailMatches, passwordMatches } = validateEmailAndPassword(regState);
  const { female, male } = state().registration.checkboxes;

  if (!emailMatches) {
    return dispatch(emailsDoNotMatch());
  }

  if (!passwordMatches) {
    return dispatch(passwordsDoNotMatch());
  }

  if (!female && !male) {
    return dispatch(noGenderSelected());
  }

  return axios
    .post(
      `${apiUrls.authServerUrl}/register`,
      transformRegStateToExpectedRequestPayload(regState)
    )
    .then((response: AxiosResponse<RegistrationResponse>) => {
      const errors = response?.data?.errors ?? "";
      const { db_e_mail_exists, db_table } = errors;

      if (errors) {
        if (db_table || db_e_mail_exists) {
          dispatch(togglePopup("Съществува потребител с тези данни.", false));
          delayExecution<typeof togglePopup>(() =>
            dispatch(togglePopup("", false))
          );
          return;
        }

        dispatch(
          togglePopup(
            "Възникна проблем при комуникцията със сървъра. Моля проверете връзката си с интернет.",
            false
          )
        );

        return delayExecution<typeof togglePopup>(() =>
          dispatch(togglePopup("", false))
        );
      }
      dispatch(registrationSuccessful(response));
    })
    .catch((error: AxiosError<Error>) => dispatch(registrationFailed(error)));
};

const loginApi = (): ThunkResult<void> => (dispatch, state) => {
  dispatch(tryToLogin());
  const loginState = state().login;

  return axios
    .post(
      `${apiUrls.authServerUrl}/login`,
      transformLoginStateToExpectedRequestPayload(loginState)
    )
    .then(
      (response: AxiosResponse<LoginSuccessResponse & LoginErrorResponse>) =>
        dispatch(tryToLoginSuccess(response))
    )
    .catch((error: AxiosError<Error>) => dispatch(registrationFailed(error)));
};

const requestReactivationCode = (): ThunkResult<void> => (dispatch, state) => {
  const { e_mail, last_digits } = getForgottenPasswordState(state());

  return axios
    .post(`${apiUrls.authServerUrl}/verify-user`, {
      e_mail: e_mail.value,
      last_dig: last_digits.value,
    })
    .then((response: RecoveryPasswordResponse) => {
      if (response?.data?.errors) {
        dispatch(
          togglePopup(
            "Моля уверете се имеила и персоналния номер са на един и същ кандидат.",
            false
          )
        );
        delayExecution<typeof togglePopup>(() =>
          dispatch(togglePopup("", false))
        );
      }
      dispatch(checkRecoveryResponse(response));
    })
    .catch((e) => console.log(e));
};

const reactivatePassword = (): ThunkResult<void> => (dispatch, state) => {
  const preliminary_number = getPreliminaryNumber(state());
  const { value: code } = getReactivationCodePasswordInput(state());
  const { value: password } = getRecoveryPasswordInput(state());
  const { value: repPassword } = getRecoveryRepeatPasswordInput(state());

  if (!code) {
    dispatch(setRecoveryCodeAsMandatory());
  }

  if (!password || !repPassword || password !== repPassword) {
    return dispatch(recoveryPasswordsDoNotMatch());
  }

  return axios
    .post(`${apiUrls.authServerUrl}/password-recovery`, {
      password,
      code,
      preliminary_number,
    })
    .then((response: RecoveryPasswordResponse) => {
      if (response?.data?.errors) {
        dispatch(
          togglePopup(
            "Възникна грешка при проверка на данните. Уверете се че активационният код е валиден.",
            false
          )
        );
        delayExecution<typeof togglePopup>(() =>
          dispatch(togglePopup("", false))
        );
      }

      if (response?.data?.success) {
        window.location.href = "/";
      }
    })
    .catch((e) => console.log(e));
};

export { registerApi, loginApi, requestReactivationCode, reactivatePassword };
