import { Fields, FormFields } from "./loginReducer";
import {
  ErrorResponseBodyInvalidFormatType,
  ErrorResponseBodyWrongCredentials,
} from "./types";

export const setErrorsFromLoginResponse = (
  state: FormFields,
  errors: ErrorResponseBodyInvalidFormatType & ErrorResponseBodyWrongCredentials
) => ({
  ...state,
  [Fields.Password]: {
    ...state[Fields.Password],
    error: (errors?.[Fields.Password] || errors?.db_e_mail_or_password) ?? false,
    touched: true,
  },
  [Fields.Email]: {
    ...state[Fields.Email],
    error: ( errors?.[Fields.Email] || errors?.db_e_mail_or_password) ?? false,
    touched: true,
  },
});

export const transformLoginStateToExpectedRequestPayload = (
  state: FormFields
) => ({
  [Fields.Email]: state[Fields.Email].value,
  [Fields.Password]: state[Fields.Password].value,
});
