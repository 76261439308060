import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

// COMPONENTS
import { colors, FlexContainer, Form } from "mu-styles/dist";

// TYPES
import {
  Checkbox as CheckboxType,
  RegistrationFields,
} from "./registrationReducer";

// SELECTORS
import { getGenderError, getRegistrationState } from "./registrationSelectors";

// ACTIONS
import {
  removeGenderError,
  setCheckboxes,
  setFieldTouched,
  SetInputActionProps,
  setInputField,
} from "./actions";

// CONSTANTS
import { rightFormConfiguration } from "./constants";

// UTILS
import { mapFieldsConfigCallback } from "./utils";
import { getRegistrationInputProps } from "../shared/utils";
import { StyledCheckbox } from "./styles";

export const RightForm = () => {
  const dispatch = useDispatch();
  const setFieldValue = (data: SetInputActionProps) => setInputField(data);
  const setTouched = (id: RegistrationFields) => dispatch(setFieldTouched(id));
  const onChange = (e: React.FormEvent<HTMLInputElement>) =>
    dispatch(setFieldValue(getRegistrationInputProps(e)));
  const onCheckboxChange = (e: React.FormEvent<HTMLInputElement>) =>
    dispatch(
      setCheckboxes(e.currentTarget.id as CheckboxType, e.currentTarget.checked)
    );
  const registrationState = useSelector(getRegistrationState, shallowEqual);
  const genderError = useSelector(getGenderError, shallowEqual);
  const { checkboxes } = registrationState;

  React.useEffect(() => {
    if (
      (checkboxes[CheckboxType.Male] || checkboxes[CheckboxType.Female]) &&
      genderError
    ) {
      dispatch(removeGenderError());
    }
  }, [checkboxes]);

  return (
    <Form>
      {rightFormConfiguration.map(
        mapFieldsConfigCallback(registrationState, onChange, setTouched)
      )}

      <FlexContainer>
        <StyledCheckbox
          genderError={genderError}
          id={CheckboxType.Male}
          text={"Мъж"}
          checked={checkboxes[CheckboxType.Male]}
          onChange={onCheckboxChange}
          color={genderError ? colors.mu_redish : colors.mu_grayish}
        />
        <StyledCheckbox
          genderError={genderError}
          id={CheckboxType.Female}
          text={"Жена"}
          checked={checkboxes[CheckboxType.Female]}
          onChange={onCheckboxChange}
          color={genderError ? colors.mu_redish : colors.mu_grayish}
        />
      </FlexContainer>
    </Form>
  );
};
