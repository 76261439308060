import styled from "styled-components";
import {
  FlexContainerWithShadow,
  colors,
} from "mu-styles/dist";

export const Wrapper = styled(FlexContainerWithShadow)`
  height: 64px;
  margin-bottom: 20px;
  background-color: ${colors.mu_purple};
  color: ${colors.mu_grayish};
`;