import { ForgottenActionsTypes, forgottenPasswordActions } from "./actions";
import { checkFieldValueForError } from "../shared/utils";

export enum ForgottenPasswordFields {
  Email = "e_mail",
  LastFourDigits = "last_digits",
}

export enum RecoveryPasswordFields {
  NewPassword = "new_password",
  NewPasswordRepeat = "new_password_repeat",
  ReactivationCode = "reactivation_code",
}

export interface FieldValueState {
  value: string;
  error: boolean;
  touched: boolean;
}

interface ForgottenPasswordState {
  [ForgottenPasswordFields.Email]: FieldValueState;
  [ForgottenPasswordFields.LastFourDigits]: FieldValueState;
  [RecoveryPasswordFields.NewPassword]: FieldValueState;
  [RecoveryPasswordFields.NewPasswordRepeat]: FieldValueState;
  [RecoveryPasswordFields.ReactivationCode]: FieldValueState;
  showReactivatePassForm: boolean;
  preliminary_number: number;
  popup: {
    shouldOpenPopup: boolean;
    text: string;
    success: boolean;
  };
}

const initialFieldValue = {
  value: "",
  error: false,
  touched: false,
};

const initialState: ForgottenPasswordState = {
  [ForgottenPasswordFields.Email]: initialFieldValue,
  [ForgottenPasswordFields.LastFourDigits]: initialFieldValue,
  [RecoveryPasswordFields.NewPassword]: initialFieldValue,
  [RecoveryPasswordFields.NewPasswordRepeat]: initialFieldValue,
  [RecoveryPasswordFields.ReactivationCode]: initialFieldValue,
  showReactivatePassForm: false,
  preliminary_number: 0,
  popup: {
    shouldOpenPopup: false,
    text: "",
    success: false,
  },
};

export function forgottenPasswordReducer(
  state = initialState,
  action: ForgottenActionsTypes
): ForgottenPasswordState {
  switch (action.type) {
    case forgottenPasswordActions.SetInput: {
      const { field, value } = action.payload;
      const trimmedValue = value.trim();

      return {
        ...state,
        [field]: {
          ...state[field],
          value: trimmedValue,
          error: checkFieldValueForError(field, trimmedValue),
        },
      };
    }

    case forgottenPasswordActions.SetInputTouched: {
      const { field } = action.payload;

      return {
        ...state,
        [field]: {
          ...state[field],
          touched: true,
        },
      };
    }

    case forgottenPasswordActions.CheckRecoveryResponse: {
      const { response } = action.payload;
      const errors = response?.data?.errors ?? "";
      const forgottenPasswordFormreset = {
        [RecoveryPasswordFields.NewPassword]: initialFieldValue,
        [RecoveryPasswordFields.NewPasswordRepeat]: initialFieldValue,
        [RecoveryPasswordFields.ReactivationCode]: initialFieldValue,
      };

      if (errors) {
        return {
          ...state,
          ...forgottenPasswordFormreset,
          showReactivatePassForm: false,
        };
      }

      const { preliminary_number } = response?.data?.success ?? {
        preliminary_number: 0,
      };

      return {
        ...state,
        showReactivatePassForm: true,
        preliminary_number,
        ...forgottenPasswordFormreset,
      };
    }

    case forgottenPasswordActions.RecoveryPasswordsDoNotMatch: {
      const pass = RecoveryPasswordFields.NewPassword;
      const repPass = RecoveryPasswordFields.NewPasswordRepeat;

      return {
        ...state,
        [pass]: {
          ...state[pass],
          error: true,
          touched: true,
        },
        [repPass]: {
          ...state[repPass],
          error: true,
          touched: true,
        },
      };
    }

    case forgottenPasswordActions.SetRecoveryCodeAsMandatory: {
      const code = RecoveryPasswordFields.ReactivationCode;
      return {
        ...state,
        [code]: {
          ...state[code],
          error: true,
          touched: true,
        },
      };
    }

    case forgottenPasswordActions.TogglePopup: {
      const { text = "", success = false } = action.payload;

      return {
        ...state,
        popup: {
          shouldOpenPopup: !state.popup.shouldOpenPopup,
          text,
          success,
        },
      };
    }

    default:
      return state;
  }
}
