import styled from "styled-components";
import {Button, colors, FlexContainer} from "mu-styles/dist";

export const StyledForgottenPassWrapper = styled(FlexContainer)`
  background-color: ${colors.mu_purple};
  color: ${colors.mu_grayish};
  height: 83vh;
`;

export const StyledButton = styled(Button)`
    outline: none;
    color: ${colors.mu_grayish};
    background-color: ${colors.mu_purple};
    margin: 1rem auto;    
    border: 2px solid ${colors.mu_grayish};
    border-radius: 0;
    width: 50%;  
    
    &:hover {
        background-color: ${colors.mu_light_purple};
    }
    
`
