import styled from "styled-components";
import { Button, colors } from "mu-styles/dist";

export const StyledButtonMuStyle = styled(Button)`
  border-radius: 0;
  height: 3rem;
  min-width: 10%;
  background-color: ${colors.mu_purple};
  color: ${colors.mu_grayish};
  outline: none;
  border: 2px solid ${colors.mu_grayish};
  font-size: 1rem;

  &:hover {
    background-color: ${colors.mu_light_purple};
  }
`;

export const StyledAnchor = styled.a`
  text-decoration: underline;
  color: ${colors.mu_grayish};
  
  &:hover {
    cursor: pointer;
    color: ${colors.mu_redish};
  }
`;

export const HeaderTextAsAnchor = styled(StyledAnchor)`
    text-decoration: none;
  &:hover {
    color: ${colors.mu_grayish};
  }
`
