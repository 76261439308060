import { ExtractActions } from "../shared/actionExtraction";
import { RegistrationFields } from "./registrationReducer";
import { Checkbox } from "./registrationReducer";
import { AxiosError, AxiosResponse } from "axios";
import { RegistrationResponse } from "./types";

export const registrationActions = {
  SetInput: "REGISTER/SET_INPUT_FIELD",
  SetFieldTouched: "REGISTER/SET_FIELD_TOUCHED",
  SetCheckboxes: "REGISTER/SET_CHECKBOXES",
  TryToRegister: "REGISTER/TRY_TO_REGISTER",
  TryToRegisterSuccess: "REGISTER/TRY_TO_REGISTER_SUCCESS",
  TryToRegisterFailed: "REGISTER/TRY_TO_REGISTER_FAILED",
  EmailsDoNotMatch: "REGISTER/EMAILS_DO_NOT_MATCH",
  NoGenderSelected: "REGISTER/NO_GENDER_SELECTED",
  RemoveGenderError: "REGISTER/REMOVE_GENDER_ERROR",
  PasswordsDoNotMatch: "REGISTER/PASSWORDS_DO_NOT_MATCH",
  ToggleRegistrationModal: "REGISTER/TOGGLE_REGISTRATION_MODAL",
} as const;

export interface SetInputActionProps {
  value: string;
  field: RegistrationFields;
}

export const setInputField = ({ value, field }: SetInputActionProps) => ({
  type: registrationActions.SetInput,
  payload: { field, value },
});

export const tryToRegister = () => ({
  type: registrationActions.TryToRegister,
});

export const setFieldTouched = (field: RegistrationFields) => ({
  type: registrationActions.SetFieldTouched,
  payload: { field },
});

export const setCheckboxes = (id: Checkbox, value: boolean) => ({
  type: registrationActions.SetCheckboxes,
  payload: { id, value },
});

export const registrationSuccessful = (
  res: AxiosResponse<RegistrationResponse>
) => ({
  type: registrationActions.TryToRegisterSuccess,
  payload: { res },
});

export const registrationFailed = (error: AxiosError<Error>) => ({
  type: registrationActions.TryToRegisterFailed,
  payload: { error },
});

export const emailsDoNotMatch = () => ({
  type: registrationActions.EmailsDoNotMatch,
});

export const noGenderSelected = () => ({
  type: registrationActions.NoGenderSelected,
});

export const removeGenderError = () => ({
  type: registrationActions.RemoveGenderError,
});

export const passwordsDoNotMatch = () => ({
  type: registrationActions.PasswordsDoNotMatch,
});

export const toggleRegistrationModal = () => ({
  type: registrationActions.ToggleRegistrationModal,
});

export const actions = {
  setInputField,
  setFieldTouched,
  setCheckboxes,
  tryToRegister,
  registrationSuccessful,
  registrationFailed,
  emailsDoNotMatch,
  passwordsDoNotMatch,
  toggleRegistrationModal,
  noGenderSelected,
  removeGenderError,
};

export type RegistrationActionsTypes = ExtractActions<typeof actions>;
