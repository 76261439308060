import {
  ForgottenPasswordFields,
  RecoveryPasswordFields,
} from "./forgottenPasswordReducer";
import { ExtractActions } from "../shared/actionExtraction";
import { RecoveryPasswordResponse } from "./types";

export const forgottenPasswordActions = {
  SetInput: "FORGOTTEN_PASSWORD/SET_INPUT_FIELD",
  SetInputTouched: "FORGOTTEN_PASSWORD/SET_FIELD_TOUCHED",
  CheckRecoveryResponse: "FORGOTTEN_PASSWORD/CHECK_RECOVERY_RESPONSE",
  RecoveryPasswordsDoNotMatch:
    "FORGOTTEN_PASSWORD/RECOVERY_PASSWORDS_DO_NOT_MATCH",
  SetRecoveryCodeAsMandatory: "FORGOTTEN_PASSWORD/SET_RECOVERY_AS_MANDATORY",
  TogglePopup: "FORGOTTEN_PASSWORD/TOGGLE_POPUP",
} as const;

export interface SetForgottenInputActionProps {
  value: string;
  field: ForgottenPasswordFields;
}

export const setInputField = ({
  field,
  value,
}: SetForgottenInputActionProps) => ({
  type: forgottenPasswordActions.SetInput,
  payload: { field, value },
});

export const setInputTouched = (
  field: ForgottenPasswordFields | RecoveryPasswordFields
) => ({
  type: forgottenPasswordActions.SetInputTouched,
  payload: { field },
});

export const checkRecoveryResponse = (response: RecoveryPasswordResponse) => ({
  type: forgottenPasswordActions.CheckRecoveryResponse,
  payload: { response },
});

export const recoveryPasswordsDoNotMatch = () => ({
  type: forgottenPasswordActions.RecoveryPasswordsDoNotMatch,
});

export const setRecoveryCodeAsMandatory = () => ({
  type: forgottenPasswordActions.SetRecoveryCodeAsMandatory,
});

export const togglePopup = (text: string, success: boolean) => ({
  type: forgottenPasswordActions.TogglePopup,
  payload: { text, success }
});

export const actions = {
  setInputField,
  setInputTouched,
  checkRecoveryResponse,
  recoveryPasswordsDoNotMatch,
  setRecoveryCodeAsMandatory,
  togglePopup,
};

export type ForgottenActionsTypes = ExtractActions<typeof actions>;
