import styled from "styled-components";
import { colors, FlexContainer, Form } from "mu-styles/dist";

export const StyledFlexContainer = styled(FlexContainer)``;

export const StyledSpanLink = styled.span`
  padding: 1rem;
  font-size: 0.8rem;

  &:hover {
    color: red;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const StyledForm = styled(Form)`
  align-items: center;
  justify-content: center;
  color: ${colors.mu_grayish};
  max-width: 100%;
`;
