import styled from "styled-components";
import { FlexContainer, device, colors, Checkbox } from "mu-styles/dist";

export const StyledFlexContainer = styled(FlexContainer)`
  @media ${device.max.mobileL} {
    display: block;
  }
`;

export const Wrapper = styled(FlexContainer)`
  background-color: ${colors.mu_purple};
`;

export const StyledCheckbox = styled(Checkbox)<{ genderError: boolean }>`
  ::before {
    position: relative;
    display: block;
    width: 11px;
    height: 11px;
    ${({ genderError }) =>
            genderError ? `border: 1px solid ${colors.mu_redish};` : ""}
  }
  
  ::after {
    position: relative;
    display: block;
    width: 11px;
    height: 11px;
    ${({ genderError }) =>
            genderError ? `border: 1px solid ${colors.mu_redish};` : ""}
  }
`;

export const StyledRegistrationModalBody = styled.div`
  text-align: left;
`;
