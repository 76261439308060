import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { rootReducer } from "./combineReducers";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = compose;

export const store = createStore(
  rootReducer,
    {},
  composeEnhancers(applyMiddleware(thunkMiddleware))
);
