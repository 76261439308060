import React from "react";
import { AppRoutes } from "./AppRoutes";
import { Router } from "react-router";
import { history } from "./history";
import { Header } from "./header/Header";
import { GlobalStyle } from "./globalStyles";
import { Provider } from "react-redux";
import { store } from "./store";

function App() {
  return (
    <>
      <Provider store={store}>
        <GlobalStyle />

        <Router history={history}>
          <Header />
          <AppRoutes />
        </Router>
      </Provider>
    </>
  );
}

export default App;
