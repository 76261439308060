import React from "react";
import { SetInputActionProps } from "../login/actions";
import { SetInputActionProps as RegistrationInputAcitonProps } from "../registration/actions";
import { Fields } from "../login/loginReducer";
import {
  adminRedirectUri,
  candidateRedirectUri,
  EMAIL_REGEX,
  MOBILE_REGEX,
  NAME_REGEX,
  PASSWORD_REGEX,
  PERSONAL_NUMBER_REGEX,
  REACTIVATION_CODE_REGEX,
} from "./constants";
import {
  RegistrationFields,
  RegistrationState,
} from "../registration/registrationReducer";
import { SuccessResponseBodyType } from "./types";
import {
  ForgottenPasswordFields,
  RecoveryPasswordFields,
} from "../password-reset/forgottenPasswordReducer";

export const getLoginInputProps = (
  e: React.FormEvent<HTMLInputElement>
): SetInputActionProps => ({
  field: e.currentTarget.id as Fields,
  value: e.currentTarget.value,
});

export const getRegistrationInputProps = (
  e: React.FormEvent<HTMLInputElement>
): RegistrationInputAcitonProps => ({
  field: e.currentTarget.id as RegistrationFields,
  value: e.currentTarget.value,
});

export const checkFieldValueForError = (
  field:
    | Fields
    | RegistrationFields
    | ForgottenPasswordFields
    | RecoveryPasswordFields,
  value: string
) => {
  if (!value) {
    return false;
  }

  switch (field) {
    case Fields.Email:
    case ForgottenPasswordFields.Email:
    case RegistrationFields.Email:
    case RegistrationFields.RepeatEmail:
      return !EMAIL_REGEX.test(value);
    case Fields.Password:
    case RegistrationFields.Password:
    case RegistrationFields.RepeatPassword:
    case RecoveryPasswordFields.NewPasswordRepeat:
    case RecoveryPasswordFields.NewPassword:
      return !PASSWORD_REGEX.test(value);
    case RegistrationFields.Name:
    case RegistrationFields.Surname:
    case RegistrationFields.MiddleName:
      return !NAME_REGEX.test(value);
    case RecoveryPasswordFields.ReactivationCode:
      return !REACTIVATION_CODE_REGEX.test(value);
    case ForgottenPasswordFields.LastFourDigits:
      return !PERSONAL_NUMBER_REGEX.test(value);
    case RegistrationFields.PersonalId:
      return !checkEgn(value);
    case RegistrationFields.Mobile:
      return !MOBILE_REGEX.test(value);
    default:
      return false;
  }
};

export const validateEmailAndPassword = (state: RegistrationState) => {
  const passVal = state[RegistrationFields.Password].value;
  const repPassVal = state[RegistrationFields.RepeatPassword].value;
  const emailVal = state[RegistrationFields.Email].value;
  const repEmailVal = state[RegistrationFields.RepeatEmail].value;

  return {
    passwordMatches: passVal && repPassVal && passVal === repPassVal,
    emailMatches: emailVal && repEmailVal && emailVal === repEmailVal,
  };
};

export const setLocalStorage = (name: string, item: string) =>
  window.localStorage.setItem(name, item);

export const redirectOnSuccessfulLoginRegister = (
  success: SuccessResponseBodyType
) => {
  setLocalStorage("token", success.token);

  let url = "";

  if (success.uri === candidateRedirectUri) {
    url = `${process.env.REACT_APP_REDIRECT_CAN_URL}${candidateRedirectUri}`;
  } else {
    url = `${process.env.REACT_APP_REDIRECT_ADM_URL}${adminRedirectUri}`;
  }

  window.location.href = url;
};

function isInteger(s: string) {
  let isInteger = true;

  for (let i = 0; i < s.length; i++) {
    // Check that current character is number.
    const c = s.charAt(i);
    if (c < "0" || c > "9") {
      isInteger = false;
      return;
    }
  }
  // All characters are numbers.
  return isInteger;
}

function daysInFebruary(year: number) {
  // February has 29 days in any year evenly divisible by four,
  // EXCEPT for centurial years which are not also divisible by 400.
  return year % 4 === 0 && (!(year % 100 === 0) || year % 400 === 0) ? 29 : 28;
}

function checkMonth(checked_month: number) {
  return !(checked_month < 1 || checked_month > 12);
}

const checkEgn = (strEGN: string) => {
  const arrEGN_WEIGHTS: number[] = [2, 4, 8, 5, 10, 9, 7, 3, 6];
  const debug = 0; // 1 is to activate debug mode

  /*check egn length*/
  if (strEGN.length !== 10) {
    if (debug) {
      console.log("bad EGN length");
    }
    return false;
  }

  /*check if the EGN is INTEGER*/
  if (!isInteger(strEGN)) {
    if (debug) {
      console.log("EGN is not integer");
    }
    return false;
  }

  /*check year, month, day*/
  let year = Number(strEGN.substr(0, 2));
  let mon = Number(strEGN.substr(2, 2));
  const day = Number(strEGN.substr(4, 2));
  //console.log(mon);
  /* calculate year and month regarding 1800, 1900, 2000*/
  if (mon > 40) {
    year += 2000;
    mon -= 40;
  } else if (mon > 20) {
    year = year + 1800;
    mon = mon - 20;
  } else {
    year = year + 1900;
  }

  if (!checkMonth(mon)) {
    if (debug) {
      console.log("Bad value per month", mon);
    }
    return false;
  }

  if (mon === 2) {
    const temp_days = daysInFebruary(year);

    if (day < 1 || day > temp_days) {
      if (debug) {
        console.log(
          "Bad number of days per February: ",
          day,
          " for year ",
          year
        );
        return false;
      }
    }
  }

  const checksum = Number(strEGN.substr(9, 1));
  let egnsum = 0;
  let valid_checksum = 0;
  const egnValues = strEGN.split("");

  for (let ii = 0; ii < 9; ii++) {
    egnsum += parseInt(egnValues[ii]) * arrEGN_WEIGHTS[ii];
  }

  valid_checksum = egnsum % 11;

  if (valid_checksum === 10) valid_checksum = 0;

  if (debug) {
    console.log("valid_checksum :", valid_checksum, " checksum : ", checksum);
  }

  if (checksum === valid_checksum) {
    return true;
  } else {
    if (debug) {
      console.log("Not valid checksum!");
    }
    return false;
  }
};

export const delayExecution = <T>(func: (arg: T) => void) =>
  setTimeout(func, 4000);
