import * as React from "react";
import { StyledForgottenPassWrapper } from "./styles";
import { shallowEqual, useSelector } from "react-redux";
import { getShowReactivatePassForm } from "./forgottenPasswordSelectors";

import { RequestCodeForm } from "./RequestCodeForm";
import { ReactivatePasswordForm } from "./ReactivatePaswordForm";

export const ForgottenPassword: React.FC = () => {
  const shouldShowReactivatePassForm = useSelector(
    getShowReactivatePassForm,
    shallowEqual
  );

  return (
    <StyledForgottenPassWrapper align={"flex-start"}>
      {shouldShowReactivatePassForm ? (
        <ReactivatePasswordForm />
      ) : (
        <RequestCodeForm />
      )}
    </StyledForgottenPassWrapper>
  );
};
