import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import {
  forgottenPassword,
  mainRoute,
  registerRoute,
} from "./shared/constants";
import { Registration } from "./registration/Registration";
import { LoginForm } from "./login/LoginForm";
import { ForgottenPassword } from "./password-reset/ForgottenPassword";
import { shallowEqual, useSelector } from "react-redux";
import { Popup } from "mu-styles/dist/popup";
import { getPopupProps } from "./password-reset/forgottenPasswordSelectors";

export const AppRoutes = () => {
  const { shouldOpenPopup, text, success } = useSelector(
    getPopupProps,
    shallowEqual
  );

  return (
    <>
      <Popup isOpened={shouldOpenPopup} text={text} success={success}/>
      <Switch>
        <Route exact path={mainRoute} component={LoginForm} />
        <Route exact path={registerRoute} component={Registration} />
        <Route exact path={forgottenPassword} component={ForgottenPassword} />
        <Redirect to={mainRoute} />
      </Switch>
    </>
  );
};
