import * as React from "react";
import { useHistory } from "react-router";

// COMPONENTS
import { Wrapper } from "./styles";
import { Title } from "mu-styles/dist";
import { HeaderTextAsAnchor } from "../shared/styles";

// CONSTANTS
import { mainRoute } from "../shared/constants";
import styled from "styled-components";

const StyledTitle = styled(Title)`
  font-size: 1rem;
`

export const Header: React.FC = () => {
  const history = useHistory();
  const navigateToLogin = () => history.push(mainRoute);

  return (
    <Wrapper>
      <HeaderTextAsAnchor onClick={navigateToLogin}>
        <StyledTitle type={"h5"}>
          МЕДИЦИНСКИ УНИВЕРСИТЕТ - СОФИЯ - система за кандидатстване на студенти
        </StyledTitle>
      </HeaderTextAsAnchor>
    </Wrapper>
  );
};
