import * as React from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";

// COMPONENTS
import { InputWithLabel, FlexContainer, colors, Title } from "mu-styles/dist";
import { StyledForm, StyledSpanLink } from "./styles";
import { StyledAnchor, StyledButtonMuStyle } from "../shared/styles";
import {
  StyledAddress,
  StyledLoginFormWrapper,
  StyledRightContainers,
} from "../main/styles";

// CONSTANTS
import { forgottenPassword, registerRoute } from "../shared/constants";

// UTILS
import { getLoginInputProps } from "../shared/utils";
import { loginApi } from "../api/apiCalls";

// SELECTORS
import { getEmail, getPassword } from "./loginSelectors";

// ACTIONS
import { SetInputActionProps, setInputField, setFieldTouched } from "./actions";

// TYPES
import { Fields } from "./loginReducer";

export const LoginForm: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    value: emailValue,
    error: emailError,
    touched: emailTouched,
  } = useSelector(getEmail);
  const {
    value: passwordValue,
    error: passwordError,
    touched: passwordTouched,
  } = useSelector(getPassword);

  const setFieldValue = (data: SetInputActionProps) => setInputField(data);
  const setTouched = (id: Fields) => dispatch(setFieldTouched(id));
  const onChange = (e: React.FormEvent<HTMLInputElement>) =>
    dispatch(setFieldValue(getLoginInputProps(e)));
  const onLogin = () => dispatch(loginApi());

  return (
    <StyledLoginFormWrapper>
      <StyledRightContainers margin={"0.5rem 0"} outline>
        <Title type={"h4"}>Вход в системата</Title>
      </StyledRightContainers>

      <StyledForm>
        <InputWithLabel
          id={Fields.Email}
          inputPlaceholder={"Потребител"}
          labelText={"Потребител"}
          onChange={onChange}
          value={emailValue}
          errorText={"Грешен потребител"}
          error={emailError}
          touched={emailTouched}
          setTouched={(id) => setTouched(id as Fields)}
          color={colors.mu_grayish}
        />

        <InputWithLabel
          id={Fields.Password}
          inputPlaceholder={"Парола"}
          labelText={"Парола"}
          type={"password"}
          errorText={"Невалидна парола"}
          onChange={onChange}
          value={passwordValue}
          error={passwordError}
          touched={passwordTouched}
          setTouched={(id) => setTouched(id as Fields)}
          color={colors.mu_grayish}
        />

        <FlexContainer margin={"1rem auto"}>
          <StyledButtonMuStyle
            maxWidth={"16rem"}
            minWidth={"100px"}
            onClick={onLogin}
          >
            Вход
          </StyledButtonMuStyle>
        </FlexContainer>

        <FlexContainer margin={"20px 0;"} direction={"column"}>
          <Title type={"h5"}>
            Ако не сте се регистрирали в системата, моля първо се регистрирайте
          </Title>
          <StyledSpanLink onClick={() => history.push(registerRoute)}>
            Регистрация
          </StyledSpanLink>
          <StyledSpanLink onClick={() => history.push(forgottenPassword)}>
            Забравена парола
          </StyledSpanLink>
        </FlexContainer>

        <StyledAddress>
          <StyledAnchor
            href={"https://mu-sofia.bg/obshti-usloviya-plashtane/"}
            target={"_blank"}
            rel={"noopener noreferrer"}
          >
            Общи условия за плащане
          </StyledAnchor>
        </StyledAddress>

        <StyledAddress>
          <StyledAnchor
            href={`MU-Sofia-Candidate-Instruction.pdf`}
            download={
              "Инструкция за работа със системата за кандидатстване на МУ.pdf"
            }
          >
            Инструкция за работа със системата за кандидатстване
          </StyledAnchor>
        </StyledAddress>
      </StyledForm>
    </StyledLoginFormWrapper>
  );
};
