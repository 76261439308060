import { ExtractActions } from "../shared/actionExtraction";
import { Fields } from "./loginReducer";
import { AxiosResponse } from "axios";
import { LoginErrorResponse, LoginSuccessResponse } from "./types";

export const loginAcitons = {
  SetInput: "LOGIN/SET_INPUT_FIELD",
  SetFieldTouched: "LOGIN/SET_FIELD_TOUCHED",
  TryToLogin: "LOGIN/TRY_TO_LOGIN",
  TryToLoginSuccess: "LOGIN/TRY_TO_LOGIN_SUCCESS",
} as const;

export interface SetInputActionProps {
  value: string;
  field: Fields;
}

export const setInputField = ({ value, field }: SetInputActionProps) => ({
  type: loginAcitons.SetInput,
  payload: { field, value },
});

export const setFieldTouched = (field: Fields) => ({
  type: loginAcitons.SetFieldTouched,
  payload: { field },
});

export const tryToLogin = () => ({
  type: loginAcitons.TryToLogin,
});

export const tryToLoginSuccess = (
  response: AxiosResponse<LoginSuccessResponse & LoginErrorResponse>
) => ({
  type: loginAcitons.TryToLoginSuccess,
  payload: { response },
});

export const actions = {
  setInputField,
  setFieldTouched,
  tryToLogin,
  tryToLoginSuccess,
};

export type LoginActionsTypes = ExtractActions<typeof actions>;
