import * as React from "react";
import { InputFieldsConfiguration } from "./constants";

import {
  Checkbox,
  RegistrationFields,
  RegistrationFormFields,
  RegistrationState,
} from "./registrationReducer";

import { colors, InputWithLabel } from "mu-styles/dist";

import {
  ExpectedRegistrationPayload,
  RegistrationErrorResponse,
} from "./types";

export const mapFieldsConfigCallback = (
  registrationState: RegistrationFormFields,
  onChange: (e: React.FormEvent<HTMLInputElement>) => void,
  setTouched: (id: RegistrationFields) => void
) => (config: InputFieldsConfiguration, idx: number): React.ReactNode => {
  const regState = registrationState;
  const value = regState[config.id].value;
  const touched = regState[config.id].touched;
  const error = regState[config.id].error;

  return (
    <InputWithLabel
      key={idx}
      {...config}
      onChange={onChange}
      value={value}
      touched={touched}
      error={error}
      setTouched={(id) => setTouched(id as RegistrationFields)}
      color={colors.mu_grayish}
    />
  );
};

export const getOppositeId = (id: Checkbox) => {
  if (id === Checkbox.Female) {
    return Checkbox.Male;
  }

  return Checkbox.Female;
};

export const setErrorsFromRegisterResponse = (
  state: RegistrationState,
  errors: RegistrationErrorResponse
): RegistrationState => ({
  ...state,
  [RegistrationFields.Password]: {
    ...state[RegistrationFields.Password],
    error: errors?.[RegistrationFields.Password] ?? false,
    touched: true,
  },
  [RegistrationFields.Email]: {
    ...state[RegistrationFields.Email],
    error: errors?.[RegistrationFields.Email] ?? false,
    touched: true,
  },
  [RegistrationFields.RepeatEmail]: {
    ...state[RegistrationFields.RepeatEmail],
    error: errors?.[RegistrationFields.Email] ?? false,
    touched: true,
  },
  [RegistrationFields.RepeatPassword]: {
    ...state[RegistrationFields.RepeatPassword],
    error: errors?.[RegistrationFields.Password] ?? false,
    touched: true,
  },
  [RegistrationFields.Mobile]: {
    ...state[RegistrationFields.Mobile],
    error: errors?.[RegistrationFields.Mobile] ?? false,
    touched: true,
  },
  [RegistrationFields.Name]: {
    ...state[RegistrationFields.Name],
    error: errors?.[RegistrationFields.Name] ?? false,
    touched: true,
  },
  [RegistrationFields.MiddleName]: {
    ...state[RegistrationFields.MiddleName],
    error: errors?.[RegistrationFields.MiddleName] ?? false,
    touched: true,
  },
  [RegistrationFields.Surname]: {
    ...state[RegistrationFields.Surname],
    error: errors?.[RegistrationFields.Surname] ?? false,
    touched: true,
  },
  [RegistrationFields.PersonalId]: {
    ...state[RegistrationFields.PersonalId],
    error: errors?.[RegistrationFields.PersonalId] ?? false,
    touched: true,
  },
});

export const transformRegStateToExpectedRequestPayload = ({
  password,
  checkboxes,
  e_mail,
  egn,
  family,
  first_name,
  sec_name,
  tel_num,
}: RegistrationState): ExpectedRegistrationPayload => ({
  [RegistrationFields.PersonalId]: egn.value,
  [RegistrationFields.Surname]: family.value,
  [RegistrationFields.MiddleName]: sec_name.value,
  [RegistrationFields.Name]: first_name.value,
  [RegistrationFields.Mobile]: tel_num.value,
  [RegistrationFields.Email]: e_mail.value,
  [RegistrationFields.Password]: password.value,
  gender: checkboxes[Checkbox.Female] ? Checkbox.Female : Checkbox.Male,
});
