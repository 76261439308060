import { loginAcitons, LoginActionsTypes } from "./actions";
import {
  checkFieldValueForError,
  redirectOnSuccessfulLoginRegister,
} from "../shared/utils";
import { setErrorsFromLoginResponse } from "./utils";

export enum Fields {
  Email = "e_mail",
  Password = "password",
}
interface FieldState {
  value: string;
  error: boolean;
  touched: boolean;
}
export type FormFields = Record<Fields, FieldState>;

export const initialLoginState: FormFields = {
  [Fields.Email]: {
    value: "",
    error: false,
    touched: false,
  },
  [Fields.Password]: {
    value: "",
    error: false,
    touched: false,
  },
};

export function loginReducer(
  state = initialLoginState,
  action: LoginActionsTypes
) {
  switch (action.type) {
    case loginAcitons.SetInput: {
      const { value, field } = action.payload;
      const trimmedValue = value.trim();

      return {
        ...state,
        [field]: {
          ...state[field],
          value: trimmedValue,
          error: checkFieldValueForError(field, trimmedValue),
        },
      };
    }

    case loginAcitons.SetFieldTouched: {
      const { field } = action.payload;

      return {
        ...state,
        [field]: {
          ...state[field],
          touched: true,
        },
      };
    }

    case loginAcitons.TryToLoginSuccess: {
      const { response } = action.payload;
      const errors = response?.data?.errors ?? "";
      const success = response?.data?.success ?? "";

      if (errors) {
        return setErrorsFromLoginResponse(state, errors);
      }

      redirectOnSuccessfulLoginRegister(success);

      return state;
    }

    default:
      return state;
  }
}
