import { MainStateType } from "../combineReducers";
import { RegistrationState } from "./registrationReducer";

export const getRegistrationState = (state: MainStateType): RegistrationState =>
  state.registration;

export const getIsRegistrationModalOpen = (state: MainStateType) =>
  getRegistrationState(state).isRegistrationModalOpen;

export const getGenderError = (state: MainStateType) =>
  getRegistrationState(state).genderError;
