import { MainStateType } from "../combineReducers";
import {Fields} from "./loginReducer";

const getLoginState = (state: MainStateType) => state?.login;

export const getEmail = (state: MainStateType) =>
  getLoginState(state)?.[Fields.Email];

export const getPassword = (state: MainStateType) =>
  getLoginState(state)?.[Fields.Password];

