import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRegistrationState } from "./registrationSelectors";
import { RegistrationFields } from "./registrationReducer";

// COMPONENTS
import { Form } from "mu-styles";

// CONSTANTS
import { leftFormConfiguration } from "./constants";

// ACTIONS
import { setFieldTouched, SetInputActionProps, setInputField } from "./actions";

// UTILS
import { mapFieldsConfigCallback } from "./utils";
import { getRegistrationInputProps } from "../shared/utils";

export const LeftForm: React.FC = () => {
  const dispatch = useDispatch();
  const setFieldValue = (data: SetInputActionProps) => setInputField(data);
  const setTouched = (id: RegistrationFields) => dispatch(setFieldTouched(id));
  const onChange = (e: React.FormEvent<HTMLInputElement>) =>
    dispatch(setFieldValue(getRegistrationInputProps(e)));
  const registrationState = useSelector(getRegistrationState);

  return (
    <Form>
      {leftFormConfiguration.map(
        mapFieldsConfigCallback(registrationState, onChange, setTouched)
      )}
    </Form>
  );
};
