import styled from "styled-components";
import { colors, device, FlexContainer } from "mu-styles/dist";

export const StyledContainer = styled(FlexContainer)`
  align-self: flex-start;
  padding: 0 1rem;
  width: 30%;
  height: 100%;
  height: 50vh;
  background-color: ${colors.mu_greenish};
  overflow-y: auto;
  color: ${colors.mu_grayish};
`;

export const StyledRightContainers = styled(FlexContainer)<{
  outline?: boolean;
  margin?: string;
}>`
  ${({ outline }) =>
    outline ? `border-bottom: 2px solid ${colors.mu_grayish};` : ""}
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  justify-self: flex-start;
  margin: ${({ margin }) => margin ? margin : "0 5px"};
`;

export const StyledContainerWrapper = styled(FlexContainer)`
  height: 83vh;
`;

export const StyledRightContainerWrapper = styled.div`
  padding: 0 1rem 1rem;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: ${colors.mu_purple};
  color: ${colors.mu_grayish};
  overflow-y: auto;
  -ms-overflow-y: auto;
`;

export const StyledLoginFormWrapper = styled(StyledRightContainerWrapper)`
  min-height: 83vh;
  justify-content: center;
`

export const StyledTitle = styled.h5`
  margin: 0;
  padding: 1rem;
  width: 45%;
  height: 2rem;
  border-bottom: 2px solid ${colors.mu_grayish};
  text-align: center;
  
  @media only screen and ${device.max.mobileS} {
    font-size: 0.5rem;
  }

  @media only screen and ${device.max.mobileL} {
    font-size: 0.8rem;
  }
`;

export const StyledAddress = styled.div`
  padding: 1rem 0.5rem;
  text-align: center;
`;

export const StyledSpravochnikContainer = styled(StyledRightContainers)`
  margin-top: 1rem;
  align-self: flex-end;
  justify-self: flex-end;
  padding: 0 20%;

  @media ${device.max.tablet} {
    padding: 0 5%;
    margin-top: 2rem;
  }
`

export const StyledInstructionsContainer = styled(StyledSpravochnikContainer)`
  margin-top: 1rem;
`
