import * as React from "react";

import { useDispatch, useSelector } from "react-redux";
import { registerApi } from "../api/apiCalls";

// COMPONENTS
import { LeftForm } from "./LeftForm";
import { RightForm } from "./RightForm";
import { StyledFlexContainer, Wrapper } from "./styles";
import { StyledButtonMuStyle } from "../shared/styles";
import { Modal as ConfirmationModal } from "mu-styles/dist";
import { ConfirmationModalBody } from "./ConfirmationModalBody";
import {
  getIsRegistrationModalOpen,
  getRegistrationState,
} from "./registrationSelectors";
import { toggleRegistrationModal } from "./actions";
import { RegistrationState } from "./registrationReducer";
import { MainStateType } from "../combineReducers";

export const Registration: React.FC = () => {
  const dispatch = useDispatch();
  const [hasReadTheInstruction, setHasReadTheInstruction] = React.useState(
    false
  );
  const isRegistrationModalOpen = useSelector<MainStateType, boolean>(
    getIsRegistrationModalOpen
  );

  const toggleModal = () => dispatch(toggleRegistrationModal());
  const tryToRegister = () => dispatch(registerApi());
  const regState = useSelector<MainStateType, RegistrationState>(
    getRegistrationState
  );

  const onSaveRegistrationDetails = React.useCallback(() => {
    if (hasReadTheInstruction) {
      toggleModal();
      tryToRegister();
    }
  }, [hasReadTheInstruction]);

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [isRegistrationModalOpen]);

  return (
    <Wrapper direction={"column"} justify="flex-start">
      <ConfirmationModal
        text={
          <ConfirmationModalBody
            onCheckboxChange={(e) =>
              setHasReadTheInstruction(e.currentTarget.checked)
            }
            {...regState}
            isConsentChecked={hasReadTheInstruction}
          />
        }
        onCancel={toggleModal}
        onSave={onSaveRegistrationDetails}
        title={
          "Моля уверете се че предоставените от вас данни са верни, преди да потвърдите вашата регистрация"
        }
        isOpen={isRegistrationModalOpen}
        shouldDisableSaveButton={!hasReadTheInstruction}
      />

      <StyledFlexContainer align="flex-start">
        <LeftForm />

        <RightForm />
      </StyledFlexContainer>

      <StyledButtonMuStyle
        maxWidth={"200px"}
        margin={"20px"}
        onClick={toggleModal}
      >
        Регистрирай ме
      </StyledButtonMuStyle>
    </Wrapper>
  );
};
