import { RegistrationFields } from "./registrationReducer";

export interface InputFieldsConfiguration {
  inputPlaceholder: string;
  labelText: string;
  type?: string;
  id: RegistrationFields;
  errorText: string
}

export const rightFormConfiguration: InputFieldsConfiguration[] = [
  {
    inputPlaceholder: "Мобилен телефон",
    labelText: "Мобилен телефон",
    id: RegistrationFields.Mobile,
    errorText: 'Невалиден телефонен номер'
  },
  {
    inputPlaceholder: "Електронна поща",
    labelText: "Електронна поща",
    id: RegistrationFields.Email,
    errorText: 'Невалиден формат на поща или пощите не съвпадат'
  },
  {
    inputPlaceholder: "Повтори ел. поща",
    labelText: "Повтори ел. поща",
    id: RegistrationFields.RepeatEmail,
    errorText: 'Невалиден формат на поща или пощите не съвпадат'
  },
  {
    inputPlaceholder: "Парола 8-20 символа, asdASD123@",
    labelText: "Парола",
    type: "password",
    id: RegistrationFields.Password,
    errorText: 'Невалиден формат на парола или паролите не съвпадат'
  },
  {
    inputPlaceholder: "Повтори парола 8-20 символа, asdASD123@",
    labelText: "Повтори парола",
    type: "password",
    id: RegistrationFields.RepeatPassword,
    errorText: 'Невалиден формат на парола или паролите не съвпадат'
  },
];

export const leftFormConfiguration: InputFieldsConfiguration[] = [
  {
    inputPlaceholder: "ЕГН",
    labelText: "ЕГН",
    id: RegistrationFields.PersonalId,
    errorText: 'Невалиден формат на ЕГН'
  },
  {
    inputPlaceholder: "Име",
    labelText: "Име",
    id: RegistrationFields.Name,
    errorText: 'Невалиден формат за име'
  },
  {
    inputPlaceholder: "Презиме",
    labelText: "Презиме",
    id: RegistrationFields.MiddleName,
    errorText: 'Невалиден формат за презиме'
  },
  {
    inputPlaceholder: "Фамилия",
    labelText: "Фамилия",
    id: RegistrationFields.Surname,
    errorText: 'Невалиден формат за фамилно име'
  },
];
