import { MainStateType } from "../combineReducers";
import {ForgottenPasswordFields, RecoveryPasswordFields} from "./forgottenPasswordReducer";

export const getForgottenPasswordState = (state: MainStateType) =>
  state.forgottenPassword;

export const getForgottenPasswordEmailInput = (state: MainStateType) =>
  getForgottenPasswordState(state).e_mail;

export const getForgottenPasswordPNInput = (state: MainStateType) =>
  getForgottenPasswordState(state)[ForgottenPasswordFields.LastFourDigits];

export const getRecoveryPasswordInput = (state: MainStateType) =>
  getForgottenPasswordState(state)[RecoveryPasswordFields.NewPassword];

export const getRecoveryRepeatPasswordInput = (state: MainStateType) =>
  getForgottenPasswordState(state)[RecoveryPasswordFields.NewPasswordRepeat];

export const getReactivationCodePasswordInput = (state: MainStateType) =>
  getForgottenPasswordState(state)[RecoveryPasswordFields.ReactivationCode];

export const getShowReactivatePassForm = (state: MainStateType) =>
  getForgottenPasswordState(state).showReactivatePassForm;

export const getPopupProps = (state: MainStateType) =>
  getForgottenPasswordState(state).popup;

export const getPreliminaryNumber = (state: MainStateType) =>
  getForgottenPasswordState(state).preliminary_number;
