export const registerRoute = "/register-student";
export const forgottenPassword = "/forgotten-password";
export const mainRoute = "/";

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
export const NAME_REGEX = /^([а-яА-Я-]{1,40}|[a-zA-Z-]{1,40})$/;
export const MOBILE_REGEX = /^\+[\d]{12}$/;
export const PERSONAL_NUMBER_REGEX = /^[\d]{4}$/;
export const REACTIVATION_CODE_REGEX = /^[\da-zA-Z]{10}$/;

export const apiUrls = {
  authServerUrl: process.env.REACT_APP_AUTH_URL || "",
};

export const candidateRedirectUri = "/candidate";
export const adminRedirectUri = "/admin";
