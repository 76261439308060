import * as React from "react";
import { Button, colors, Form, InputWithLabel } from "mu-styles/dist";
import { StyledButton } from "./styles";
import {
  ForgottenPasswordFields,
  RecoveryPasswordFields,
} from "./forgottenPasswordReducer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setInputField, setInputTouched } from "./actions";
import {
  getReactivationCodePasswordInput,
  getRecoveryPasswordInput,
  getRecoveryRepeatPasswordInput,
} from "./forgottenPasswordSelectors";
import { reactivatePassword } from "../api/apiCalls";

export const ReactivatePasswordForm: React.FC = () => {
  const dispatch = useDispatch();
  const setInput = (e: React.FormEvent<HTMLInputElement>) =>
    dispatch(
      setInputField({
        field: e.currentTarget.id as ForgottenPasswordFields,
        value: e.currentTarget.value,
      })
    );
  const setTouched = (id: string) =>
    dispatch(setInputTouched(id as ForgottenPasswordFields));
  const callRecoverPassApi = () => dispatch(reactivatePassword())

  const {
    touched: newPassTouched,
    error: newPassError,
    value: newPassValue,
  } = useSelector(getRecoveryPasswordInput, shallowEqual);
  const {
    value: newPassRepValue,
    error: newPassRepError,
    touched: newPassRepTouched,
  } = useSelector(getRecoveryRepeatPasswordInput, shallowEqual);
  const { touched, error, value } = useSelector(
    getReactivationCodePasswordInput,
    shallowEqual
  );

  return (
    <Form>
      <InputWithLabel
        id={RecoveryPasswordFields.NewPassword}
        color={colors.mu_grayish}
        labelText={"Нова парола"}
        inputPlaceholder={"Нова парола"}
        onChange={setInput}
        value={newPassValue}
        error={newPassError}
        touched={newPassTouched}
        setTouched={setTouched}
        type={'password'}
        errorText={"Невалиден формат на парола или паролите не съвпадат"}
      />
      <InputWithLabel
        id={RecoveryPasswordFields.NewPasswordRepeat}
        color={colors.mu_grayish}
        labelText={"Повтори парола"}
        inputPlaceholder={"Повтори парола"}
        onChange={setInput}
        value={newPassRepValue}
        error={newPassRepError}
        touched={newPassRepTouched}
        setTouched={setTouched}
        type={'password'}
        errorText={"Невалиден формат на парола или паролите не съвпадат"}
      />
      <InputWithLabel
        id={RecoveryPasswordFields.ReactivationCode}
        color={colors.mu_grayish}
        labelText={"Код"}
        inputPlaceholder={"Въведете код за реактивация"}
        onChange={setInput}
        value={value}
        error={error}
        touched={touched}
        setTouched={setTouched}
        errorText={"Невалиден формат на персонален номер"}
      />
      <StyledButton onClick={callRecoverPassApi}>
        Възстанови парола
      </StyledButton>
    </Form>
  );
};
