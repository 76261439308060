import { combineReducers } from "redux";
import { loginReducer } from "./login/loginReducer";
import { registrationReducer } from "./registration/registrationReducer";
import { forgottenPasswordReducer } from "./password-reset/forgottenPasswordReducer";

export const rootReducer = combineReducers({
  login: loginReducer,
  registration: registrationReducer,
  forgottenPassword: forgottenPasswordReducer,
});

export type MainStateType = ReturnType<typeof rootReducer>;
